import { ApiRequestContext } from '@ocodelib/api-common';
import { AxiosRequestConfig } from 'axios';

export function overrideReqCtxConfig(
  requestCtx: ApiRequestContext | undefined,
  newConfig: AxiosRequestConfig,
) {
  if (requestCtx) {
    requestCtx.config = { ...requestCtx.config, ...newConfig };
    return requestCtx;
  }

  return ApiRequestContext.of({
    config: {
      ...newConfig,
    },
  });
}
